import React, { useState, useEffect } from "react";
import { Link } from "react-router";
import { motion } from "framer-motion";
import { Categories, ProductDictionary } from "./DataDictionary";

function App() {
  const [selectedCategory, setSelectedCategory] = useState(Categories.CAT_ONE);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const categoryProducts = Object.values(ProductDictionary).filter(
      (product) => product.category === selectedCategory.id
    );
    setProducts(categoryProducts);
  }, [selectedCategory]);

  return (
    <div className="max-w-screen-xxl mx-auto p-6 bg-[#faf9f6] text-[#2e2e2e]">
      <section className="flex flex-col items-center text-center py-20 bg-[#f4efe9] text-[#5a4636] font-[Garamond]">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="mt-6"
        >
          <h1 className="text-4xl sm:text-5xl font-bold">Saumya Kashyap</h1>
          <p className="mt-2 text-lg sm:text-xl italic">Internationally Recognised Artist</p>
        </motion.div>
      </section>
      <section className="py-20 bg-[#EDE9DD]">
        <div className="max-w-5xl mx-auto flex flex-col md:flex-row items-center gap-8">
          <div className="flex-1 text-left">
            <p className="text-lg mb-3">A passionate artist and creative entrepreneur, she has explored various forms of artistic expression, including sketching, painting with diverse media, photography, and more.</p>
            <p className="text-lg mb-3">After earning a degree in Computer Science from IIIT Hyderabad in 2006, she left her IT career to pursue her artistic calling. Her first venture involved handcrafted designer candles, which she showcased at Shilparamam in Hyderabad. This experience ignited a deep and enduring passion for creativity.</p>
            <p className="text-lg mb-3">Following a move to Bangalore, she completed the MPWE program at IIM Bangalore and launched a professional photography venture, specializing in capturing cherished memories of children and families.</p>
            <p className="text-lg mb-3">After taking a long break to focus on her family, she is now embarking on an exciting new chapter. With a deep commitment to creating unique and meaningful art that generates opportunities for local artisans, she has founded Vystrit. This new venture focuses on fusion art using mixed media, bringing innovative and never seen before artistic expressions into daily living through décor, furniture, lighting, and more.</p>
          </div>
          <img src="https://vystrit.s3.ap-south-1.amazonaws.com/SK-image.jpg" 
            alt="Artist Portrait" 
            className="w-full max-w-sm rounded-lg shadow-lg" 
          />
        </div>
      </section>
      <section className="py-20 text-center">
        <h2 className="text-3xl font-bold mb-6">Featured Arts</h2>
        <div className="flex flex-wrap justify-center gap-3 mb-6">
          {Object.values(Categories).map((category) => (
            <button
              key={category.id}
              className={`px-4 py-2 rounded-lg font-bold transition ${
                selectedCategory.id === category.id ? "bg-[#5a4636] text-[#fff]" : "bg-[#e0dcd3] text-[#5a4636]"
              }`}
              onClick={() => setSelectedCategory(category)}
            >
              {category.name}
            </button>
          ))}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4">
          {products.map((art, index) => (
            <motion.div
              key={index}
              className="bg-white p-4 rounded-lg shadow-lg"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.7, delay: index * 0.3 }}
            >
              <Link to={`/product/${art.id}`} className="block text-center">
                <img src={art.images?.[0]} alt={art.title} className="w-full h-90 object-cover rounded-lg mb-3" />
                <p className="text-l font-bold text-[#5a4636]">{art.name}</p>
              </Link>
            </motion.div>
          ))}
        </div>
      </section>
      <section className="py-20 text-center bg-[#f4efe9]">
        <h2 className="text-3xl font-bold mb-4">Exclusive Commissions & Inquiries</h2>
        <p className="text-lg mb-2">Discover a world of bespoke elegance. Contact us for private viewings and collaborations.</p>
        <p className="text-lg">Email: hello@vystrit.com | Phone: +91 96112 12428</p>
        <a
          href="https://forms.gle/78vZVotN9t75dANL9"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-6 inline-block bg-[#9B6B50] text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-[#7a4f38] transition"
        >
          Request to Acquire
        </a>
      </section>
    </div>
  );
}

export default App;
