import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { ProductDictionary } from "./DataDictionary";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const ProductPage = () => {
  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let productData = {};

  Object.keys(ProductDictionary).map((productKey) => {
    const product = ProductDictionary[productKey];
    if (id === product?.id) {
      productData = product;
    }
  });

  if (!productData) {
    return <h2 className="text-center py-12 text-xl font-semibold">Product not found</h2>;
  }

  return (
    <div className="max-w-xxl mx-auto p-6 bg-[#EDE9DD] text-[#151515] font-[Garamond]">
      <section className="flex flex-col md:flex-row items-center gap-12 py-12">
        <div className="w-full md:w-1/2 relative">
          <Carousel showThumbs={true} showArrows={true} infiniteLoop useKeyboardArrows dynamicHeight className="rounded-xl">
            {productData.images?.map((image, index) => (
              <div key={index} className="flex justify-center">
                <img
                  src={image}
                  alt={`Product Image ${index + 1}`}
                  className="w-full max-h-[2000px] object-contain rounded-xl"
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="flex-1">
          <h1 className="text-4xl font-bold">{productData.name}</h1>
          <h3 className="text-2xl font-bold mt-6">Story</h3>
          <p className="text-lg mt-4">{productData.description}</p>
          {productData?.details ? (
            <>
              <h3 className="text-2xl font-bold mt-6">Finer Details</h3>
              <p className="text-lg mt-4">{productData.details}</p>
            </>
          ) : null}
          <h3 className="text-2xl font-bold mt-6">Features</h3>
          <ul className="list-none mt-2 space-y-1">
            {Object.entries(productData.features).map(([key, value]) => (
              <li key={key} className="text-md">
                <strong>{key}:</strong> {value}
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section className="text-center py-20 bg-[#f4efe9]">
        <h2 className="text-4xl font-bold">Exclusive Commissions & Inquiries</h2>
        <p className="text-xl mt-4">Discover a world of bespoke elegance. Contact us for private viewings and collaborations.</p>
        <p className="text-lg mt-2">Email: hello@vystrit.com</p>
        <p className="text-lg">Phone: +91 96112 12428</p>
        <a
          href="https://forms.gle/78vZVotN9t75dANL9"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-6 inline-block bg-[#9B6B50] text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-[#7a4f38] transition"
        >
          Request to Acquire
        </a>
      </section>
    </div>
  );
};

export default ProductPage;
