const Categories = {
  "CAT_ONE": {
    "id": "SKC000001",
    "name": "SIGNATURE COLLECTION",
    "slug": "SIGNATURE COLLECTION",
    "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    "isActive": true
  },
  "CAT_TWO": {
    "id": "SKC000002",
    "name": "PHOTOS",
    "slug": "PHOTOS",
    "description": "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
    "isActive": true
  }
}

const ProductDictionary = {
  "PROD_ONE": {
    "id": "SKP000001",
    "name": "The Masks We Wear (4ftx8ft)",
    "category": "SKC000001",
    "price": "₹632,000",
    "description": "The Masks We Wear - Wearing a mask only conceals a portion of our true selves. And the true self is so ingrained in the fabric of our being that we cannot shrug it off. This conceptual art explores two faces and their two masks. The woman and the man bear a resemblance to royalty, yet they could be anyone. The facades are in the center and real selves on the sides. In the center, behind their masks, both of them are putting up an affluent facade for the world to see. The man's genuine emotions are visible on the right side of his facade, revealing his inner sadness. He comes from money and shows-off through slightly garish face and masks both. It is also highlighted with a more colorful facade along with fish motif symbolizing abundance. The woman's true appearance is on the left, characterised by earthy tones and a natural look without makeup, adorned with flowers in her hair. Her starfish symbolises good fortune. She believes that she is fortunate to meet and marry a wealthy individual. On her facade, we see that she is now adorning larger and more opulent jewellery. However, despite her material wealth, she remains discontented, unsure of her path forward. ",
    "details": `Looking closely, you can observe the sadness in eyes of the true selves. Queen's crown is partial, signifying that she is still in the process of moving in to ostentatious abundance. Behind the masks, we can see more stoic eyes still carrying a hint of sadness. Some bit of earthy tones from the girl's past are still carried on to the mask. Her lips are now crooked cupid's bow - a love story that is imperfect.`,
    "features": {
      "Type": "Mixed Media",
      "Series": "Designed by Saumya Kashyap",
      "Orientation": "Landscape",
      "Size": "Height:4 feet x Width:8 feet | Depth:2.7 inches",
      "Weight": "27.6 kg",
      "Material": "Handmade Teak wood frame, bolstered with a rectangular mild steel frame, handwoven natural star pattern rattan mesh (cane webbing), mixed textile.",
      "Art": "Varied textile hand-stitched directly on the stretched cane webbing"
    },
    "isActive": true,
    "images": ["https://vystrit.s3.ap-south-1.amazonaws.com/maskswewear-portrait+(1).jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/The_masks_we_wear_with_chair_lamp.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/the_masks_we_wear_with_saumya.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/the_masks_we_wear_king.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/the_masks_we_wear_isolated.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/the_masks_we_wear_closeup1.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/The_maska_we_wear_queen.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/the_maska_we_wear_closeup_eye.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/Th_masks_we_wear_dimensions.jpg"],
    "tags": ["luxury", "handmade", "furniture"]
  },
  "PROD_TWO": {
    "id": "SKP000002",
    "name": "Enlightenment ( 4ftx8ft )",
    "category": "SKC000001",
    "price": "₹492,000",
    "description": "Enlightenment - flowers blooming out of head and spreading out signify personal growth and spirituality. A crown of butterflies represents cross-pollination of thoughts, shaping a modern mind. The flowers represent growth and wisdom. In this conceptual art, the flowers have taken over showing immense growth - both inward and outward. The crown signifies both the power that comes with knowledge and self-discovery, and the dignity that comes as we learn and become more respectful of others. Dignity is independent of social status, physical or intellectual prowess.",
    "details": "Notice the dark rose that is reminiscent of a human brain, flowers entangled with hair seamlessly, eyes covered to show internal reflection rather than judgement of what is outside. Three-dimensional, transparent-bodied butterflies represent honesty in communication, butterflies are positioned upwards and slightly downwards forming a crown",
    "features": {
      "Type": "Mixed Media",
      "Series": "Designed by Saumya Kashyap",
      "Orientation": "Portrait",
      "Size": "Height: 8 feet x Width: 4 feet | Depth: 2.7 inches",
      "Weight": "65 kg",
      "Material": "Handmade Teak wood frame bolstered with concealed steel frame, handwoven natural star pattern rattan mesh (cane webbing).",
      "Art": "Mixed media textile hand stitched directly on Cane webbing."
    },
    "isActive": true,
    "images": ["https://vystrit.s3.ap-south-1.amazonaws.com/enlightenment_growth_girl_isolated+(1).jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/enlightenment_growth_girl_closeup_face.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/enlightenment_growth_girl_closeup_lips.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/enlightenment_growth_girl_closeup_pansy.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/enlightenment_growth_girl_closeup.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/enlightenment_growth_girl_full_with_rug.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/enlightenment_growth_girl_rose_closeup.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/enlightenment_growth_girl_with_saumya.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/growth_girl_with_chair-dimensions.jpg"],
    "tags": ["luxury", "handmade", "furniture"]
  },
  "PROD_THREE": {
    "id": "SKP000003",
    "name": "Modern Tree of Life (4ftx6ft)",
    "category": "SKC000001",
    "price": "₹386,000",
    "description": "Modern Tree of Life - This conceptual art captures the imperfections of the modern life, exploring the emphasis on individuality and nuclear living. ",
    "details": "Every bird is different - in size, shape, and color. No two birds are on the same branch. The birds are not looking at each other. There is a parent bird - no longer waiting for anyone, nostalgic bird looking at the nest but too many barriers to cross, an ambitious one, and the one who is depressed, hiding in plain sight. Can you identify the youngest and the oldest birds? (Hint: look at the color of the wood that joins a particular branch)",
    "features": {
      "Type": "Mixed Media",
      "Series": "Designed by Saumya Kashyap",
      "Orientation": "Portrait",
      "Size": "Height: 6 feet x  Width: 4 feet | Depth: 2.2 inches ",
      "Weight": "18.6 kg",
      "Material": "Handmade Teak wood frame bolstered with concealed steel frame, handwoven natural star pattern rattan mesh (cane webbing)",
      "Art": "Mixed media textile hand stitched directly on Cane webbing"
    },
    "isActive": true,
    "images": ["https://vystrit.s3.ap-south-1.amazonaws.com/ModernTreeofLifebySaumyaKashyap+(1).png"],
    "tags": ["luxury", "handmade", "furniture"]
  },
  "PROD_FOUR": {
    "id": "SKP000004",
    "name": "Pop Diva (4ftx6ft)",
    "category": "SKC000001",
    "price": "₹386,000",
    "description": "Pop Diva - This conceptual art tries to capture the persona of a Pop Diva. Their stage presence makes them larger than life yet their work is relatable. The face is made of numerous shades, harmoniously blending together - representing the motley of emotions that a pop star conveys through their work. Beyond all the glitter, we see a graceful face, a relaxed demeanor.",
    "details": "Look for how the sharp chin depresses a bit as it rests on the arm. Subtle variations of hues creating a glittering eye. The nose has as many as 14 shades and tints.",
    "features": {
      "Type": "Mixed Media",
      "Series": "Designed by Saumya Kashyap",
      "Orientation": "Portrait",
      "Size": "Height: 6 feet x Width: 4 feet | Depth: 2.2 inches",
      "Weight": "18.6 kg",
      "Material": "Handmade teak wood frame, natural star pattern rattan mesh (cane webbing)",
      "Art": "Hand carved and finished custom shape"
    },
    "isActive": true,
    "images": ["https://vystrit.s3.ap-south-1.amazonaws.com/popdivaongreenwall.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/pop_divawithdimensions.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/popdivacloseup.jpg"],
    "tags": ["luxury", "handmade", "furniture"]
  },
  "PROD_FIVE": {
    "id": "SKP000005",
    "name": "Lady in Red Saree (4ftx6ft)",
    "category": "SKC000001",
    "price": "₹298,000",
    "description": "Lady in Red Saree - Red represents confidence, passion and courage of the woman at the same time capturing the essence of the red saree traditionally used for festive occasions and is associated with love and grace. Yellow brings out the warmth, positivity and optimism of the person. Traditionally, yellow is considered an auspicious color, and is associated with energy.",
    "details": "You can notice the creases and folds in the saree. The way it has been woven along the art, the ruby and gold jewelry is prominent yet not taking away the focus from this beautiful saree. The calm demeanor and simplicity on the face complements the vibrant saree",
    "features": {
      "Type": "Mixed Media",
      "Series": "Designed by Saumya Kashyap",
      "Orientation": "Portrait",
      "Size": "Height: 6 feet x Width: 4 feet | Depth: 2.3 inches",
      "Weight": "21.4 kg",
      "Material": "Handmade teak wood frame, natural star pattern rattan mesh (cane webbing)",
      "Art": "Hand carved and finished custom shape"
    },
    "isActive": true,
    "images": ["https://vystrit.s3.ap-south-1.amazonaws.com/ladyinredgreenwall_c73427e8-0cfd-44cc-bc7e-32b271b688f7.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/ladyinredwithdimensions.jpg"],
    "tags": ["luxury", "handmade", "furniture"]
  },  
  "PROD_SIX": {
    "id": "SKP000006",
    "name": "Starry Night - Reimagined (6ftx3ft)",
    "category": "SKC000001",
    "price": "₹215,000",
    "description": "Starry Night (Reimagined) - A modern take on the classic, this is the artist's interpretation of the Starry Night on a completely new medium, keeping the feel of the original alive. While capturing the essence of the original masterpiece by Van Gogh, this inspired art is minimalistic and less busy on the new canvas. There are interesting patterns used to with the fabric and placement of the elements to make this canvas standout in the background of hand painted (black) natural cane",
    "features": {
      "Type": "Mixed Media",
      "Series": "Designed by Saumya Kashyap",
      "Orientation": "Landscape",
      "Size": "Height: 6.3 feet x Width: 3.4 feet | Depth: 2.5 inches",
      "Weight": "18.6 kg",
      "Material": "Handmade teak wood frame, natural star pattern rattan mesh (cane webbing)",
      "Art": "Hand carved and finished custom shape"
    },
    "isActive": true,
    "images": ["https://vystrit.s3.ap-south-1.amazonaws.com/starrynightreimagined-front.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/starrynightreimagined-1.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/starrynightreimagineddimensions.jpg"],
    "tags": ["luxury", "handmade", "furniture"]
  },  
  "PROD_SEVEN": {
    "id": "SKP000007",
    "name": "A Beacon of Hope (3ft x 4ft)",
    "category": "SKC000001",
    "price": "₹92,000",
    "description": "This conceptual art explores a dilapidated lighthouse that still sits high up and continues to guide the sailors. An old lighthouse with its keeper’s house is seen from the perspective of a large tree. Despite challenging conditions, the keeper ensures safe navigation. The bright colors in and around the lighthouse signify hope and positivity.",
    "details": "You will notice a crumbling roof of the house - looking crooked on the left side. The paint is peeling off from the lighthouse and the hut-house. On the left you see a calm sea with a water vessel navigating confidently.",
    "features": {
      "Type": "Mixed Media",
      "Series": "Designed by Saumya Kashyap",
      "Orientation": "Portrait",
      "Size": "Height: 4 feet x Width: 3 feet | Depth: 2 inch",
      "Weight": "6.8 kg",
      "Material": "Handmade teak wood frame, natural star pattern rattan mesh (cane webbing)",
      "Art": "Hand carved and finished custom shape"
    },
    "isActive": true,
    "images": ["https://vystrit.s3.ap-south-1.amazonaws.com/Beacon_of_Hope_-_limited_edition_art_by_Saumya_Kashyap.png", "https://vystrit.s3.ap-south-1.amazonaws.com/Lighthousecloseup1.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/Lighthousecloseup2.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/Lighthousecloseup3.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/Lighthousecloseup4.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/lighthousewithchairandtable.jpg", "https://vystrit.s3.ap-south-1.amazonaws.com/Lighthousewithdimensions.jpg"],
    "tags": ["luxury", "handmade", "furniture"]
  }
}

module.exports = {
  Categories,
  ProductDictionary
}
